// Pages for MOE

@import "och-home-page";


//Global Common Styles and Overrides of Base Theme starts
body {
    padding: 0;
    margin: 0;
    background-color: $moe-gray-lgt;
}
//Global Common Styles and Overrides of Base Theme ends