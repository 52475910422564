//och-home-page

.ms-tile {
    &-list__title {
        white-space: normal;
    }
    &__item > div {
        height: 100%;
    }
}

//OCH Hero
.och-hero{
    &__content .och-hero__text {
        margin-left: 6.3rem;
        // Mobile and Tablet Responsive UI
        @media (max-width: $msv-breakpoint-l) {
            margin-left: 0;
        }
    }
}

//OCH Home page tiles
.moe-tile-medium-image-container .ms-tile-list-container {
    width: auto;
}

.moe-och-home {
    &-container main {
        background-color: $moe-gray-lgt;
    }
    &-latest-resource,
    &-trending,
    &-most-popular {
        & .ms-product-collection {
            margin-bottom: 48px;
            &__heading {
                font-weight: bold;
            }
        }
    }

    &-search-container {
        margin-bottom: 24px;
        & .ms-search {
            justify-content: center;
            &__searchForm {
                justify-content: center;
            }
        }
    }
}
.moe-och-latest-news {
    margin-bottom: 48px;
}
