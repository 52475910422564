//nzc search

.och-search {
    &-wrap {
        .ms-search__searchForm {
            outline: 5px solid $moe-border-peach;
        }
        & .moe-local-search-container {
            & .moe-local-search-form {
                outline: 5px solid $moe-border-peach !important;
            }
        }
    }
}
