//och-header

//Base theme overrides for MOE sites
.ms-header {
    &__container {
        display: flex;
        align-items: center;
        max-width: $msv-breakpoint-xl;
        margin: 0 auto;
    }
    & .ms-nav__list__item__link {
        font-weight: bold !important;
    }
    & .ms-header__nav-icon {
        //Responsive UI
        @media (max-width: $msv-breakpoint-l) {
            display: inline-flex;
        }
    }
    .moe-header__mobile-container-right {
        //Responsive UI
        @media (max-width: $msv-breakpoint-l) {
            position: absolute;
            right: 1rem;
        }
        @media (max-width: $msv-breakpoint-m) {
            right: 0;
        }
    }
    &__modal {
        .msc-wishlist-icon,
        .ms-header__signin-button {
            display: none !important;
        }
    }
}

.och-header {
    .ms-header__account-info,
    .msc-wishlist-icon,
    .ms-header__signin-button,
    .msc-cart-icon,
    .ms-header__divider,
    .moe-switchsite {
        display: none !important;
    }
    & .ms-nav.desktop-vp,
    .ms-nav.mobile-vp {
        //Responsive UI
        @media (max-width: $msv-breakpoint-l) {
            display: none;
        }
    }
}

//language toggle english - maori
.moe-language-toggle-holder {
    &--desktop {
        display: block;
        @media screen and (max-width: $msv-breakpoint-l) {
            display: none;
        }
        & .moe-btn-language-toggle {
            margin-right: 1rem;
            padding: 0.625rem 0rem;
        }
    }
    &--mobile {
        display: block;
        margin-top: 1rem;
        margin-bottom: 1rem;
        @media screen and (min-width: $msv-breakpoint-l) {
            display: none;
        }
        & .moe-btn-language-toggle {
            padding: 0.625rem 0rem;
        }
    }
}
//to fix the navigation gap
.ms-nav {
    gap: 20px;
}
