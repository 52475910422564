//och-hero-section

//OCH Hero
.och-hero {
    &-wrap {
        max-width: $msv-breakpoint-xl;
        margin: 0 auto;
        & .och-hero__bgimg {
            position: absolute;
            top: 0;
            max-width: 100%;
        }
    }
}
